import DesktopHeader from '@modules/Header/headers/desktopHeader'
import MobileHeader from '@modules/Header/headers/mobileHeader'
import * as React from 'react'
import { usePage } from '@griddo/core'
import { useDataLayer } from '@hooks/index'

import {
  DATALAYER_CUSTOM_EVENTS,
  DATALAYER_MODULES,
} from '@constants/dataLayer'
import { getWindow } from 'ssr-window'

const BasicMoreInfo = {
  default: {
    component: 'BasicMoreInfo',
    title: '¿Hablamos?',
    subtitle: 'Cuéntanos sobre ti.',
    description:
      'Si tienes dudas escríbenos y nos pondremos en contacto contigo.',
    buttonText: 'Solicita Información',
    hasDistributorData: true,
    data: {
      mode: 'auto',
      sources: [{ structuredData: 'PROGRAMS' }],
      order: 'recent',
    },
    formType: '05',
    button: {
      component: 'Button',
      buttonText: 'Solicita información',
      appearance: 'primary01',
      url: {
        href: '',
        linkToURL: null,
        newTab: false,
        noFollow: false,
      },
      isLink: 'link',
      elements: [],
    },
  },
}

const window = getWindow()

const Header = ({
  mainMenu,
  phoneNumber,
  searchBox,
  campusUrl,
  navigationLanguages,
  button,
}) => {
  const addLevelToMenu = (menu, level = 1, parentID = 0) => {
    return menu?.map((item) => {
      item.level = level
      item.parentID = parentID
      if (item.children?.length > 0)
        addLevelToMenu(item.children, level + 1, item.id)
      return item
    })
  }

  const mainMenuElements =
    mainMenu?.elements?.length > 0 ? addLevelToMenu(mainMenu.elements) : []

  const { template, pageLanguages, languageId } = usePage()

  let moreInfo = []
  if (template && template?.moreInfo) {
    const { moreInfo: info } = template
    moreInfo = {
      modules: [
        {
          component: 'BasicMoreInfo',
          elements: [info?.modules[0]],
        },
      ],
    }
  } else {
    moreInfo = {
      modules: [
        {
          component: 'BasicMoreInfo',
          elements: [{ ...BasicMoreInfo.default }],
        },
      ],
    }
  }

  const { templateType } =
    (!!template && template?.length > 0) || 'BasicTemplate'
  const buttonText = moreInfo?.modules[0]?.elements[0]?.buttonText

  const { setTypeEvent, setTemplateType, setExtraData } = useDataLayer()

  const handleEventPhoneDataLayer = ({ intoFooter = false }) => {
    setTemplateType(templateType)
    import('@utils/DataLayer').then(({ mountDataLayerCallToPhone }) => {
      const dataLayer = mountDataLayerCallToPhone({
        module: intoFooter
          ? DATALAYER_MODULES.FOOTER
          : DATALAYER_MODULES.HEADER,
      })
      setExtraData(dataLayer)
      setTypeEvent(DATALAYER_CUSTOM_EVENTS.CALL_TO_PHONE)
    })
  }

  const handleEventClickItemMenu = ({ target }) => {
    window.sessionStorage.setItem(
      'click-event-link',
      JSON.stringify({
        type: DATALAYER_MODULES.HEADER,
        url: target?.href ? target.href : '',
      })
    )
  }

  return (
    <>
      <DesktopHeader
        pageLanguages={pageLanguages}
        languageId={languageId}
        handleEventClickItemMenu={handleEventClickItemMenu}
        mainMenuElements={mainMenuElements}
        buttonText={buttonText}
        navigationLanguages={navigationLanguages}
        campusUrl={campusUrl}
        phoneNumber={phoneNumber}
        searchBox={searchBox}
        handleEventPhoneDataLayer={handleEventPhoneDataLayer}
        button={button}
      />
      <MobileHeader
        pageLanguages={pageLanguages}
        languageId={languageId}
        mainMenuElements={mainMenuElements}
        buttonText={buttonText}
        handleEventClickItemMenu={handleEventClickItemMenu}
        navigationLanguages={navigationLanguages}
        campusUrl={campusUrl}
        phoneNumber={phoneNumber}
        searchBox={searchBox}
        handleEventPhoneDataLayer={handleEventPhoneDataLayer}
        templateType={templateType}
        button={button}
      />
    </>
  )
}

export default Header
