import { Button, ImageTrans, MaxWidth } from '@components/index'
import { SearchBox } from '@modules/index'
import { lazy, useMemo, useRef, useState } from 'react'
import * as styles from './mobile.module.scss'
import MainNavigationLargeIcon from '../../../../static/images_NO_VALE/svg/lines/MainNavigation/MainNavigationLarge.svg'

const Logo = lazy(() => import('@modules/Header/components/Logo'))

const MenuSearch = lazy(() => import('@modules/Header/components/Search'))
const LangAndAccess = lazy(() =>
  import('@modules/Header/components/LangAndAccess')
)
const StickyBottom = lazy(() =>
  import('@modules/Header/components/StickyBottom')
)
const MountLink = lazy(() => import('@modules/Header/headers/Links'))

const LoadSubMenus = ({
  menu,
  setActiveMenu,
  campusUrl,
  pageLanguages,
  languageId,
  handleEventClickItemMenu,
}) => {
  return useMemo(() => {
    if (!menu || menu.length === 0) {
      return null
    }

    return menu.map((item, idx) => {
      if (!item.children || item.children.length === 0) {
        return null
      }

      return (
        <MountMenu
          key={idx}
          menu={item.children}
          menuId={item.id}
          image={item.image}
          setActiveMenu={setActiveMenu}
          campusUrl={campusUrl}
          pageLanguages={pageLanguages}
          languageId={languageId}
          handleEventClickItemMenu={handleEventClickItemMenu}
        />
      )
    })
  }, [
    menu,
    setActiveMenu,
    campusUrl,
    pageLanguages,
    languageId,
    handleEventClickItemMenu,
  ])
}

export function MountMenu({
  menu,
  menuId = 0,
  image,
  setActiveMenu,
  campusUrl,
  pageLanguages,
  languageId,
  handleEventClickItemMenu,
}) {
  const level = menu.length > 0 ? menu[0]?.level : 0

  return (
    <>
      <div
        className={`${styles.menuVertical}  ${
          menuId > 0 ? styles.submenu : ''
        }  js-menu`}
        data-menu-id={`menu-${menuId}`}
      >
        <div data-level={`menu-${level}`} className={styles.menu}>
          <ul className={styles.list}>
            {menu.length > 0 &&
              menu.map((link, index) => (
                <MountLink
                  key={index}
                  link={link}
                  menuType="mobile"
                  setActiveMenu={setActiveMenu}
                  handleEventClickItemMenu={handleEventClickItemMenu}
                />
              ))}
          </ul>
        </div>
        {level === 1 && (
          <div className={styles.right}>
            <LangAndAccess
              needSocialsLinks={true}
              menuRowStyle={styles.menuRow}
              rowLinksStyles={styles.rowLinks}
              socialIconsStyles={styles.socialIcons}
              campusUrl={campusUrl}
              pageLanguages={pageLanguages}
              languageId={languageId}
            />
          </div>
        )}

        {level === 2 && (
          <div className={styles.right}>
            <div className={`${styles.lineLarge}`}>
              <MainNavigationLargeIcon />
            </div>
          </div>
        )}
        {image?.url && level === 2 && (
          <div className={styles.image}>
            <ImageTrans
              ratio={550 / 280}
              image={image}
              alt={image?.alt}
              responsive={[
                { breakpoint: null, width: '300px' },
                { breakpoint: '768px', width: '540px' },
              ]}
            />
          </div>
        )}
      </div>

      {level < 3 && (
        <LoadSubMenus
          menu={menu}
          prevMenuId={menuId}
          setActiveMenu={setActiveMenu}
          parent={menu}
          campusUrl={campusUrl}
          pageLanguages={pageLanguages}
          languageId={languageId}
          handleEventClickItemMenu={handleEventClickItemMenu}
        />
      )}
    </>
  )
}

const MountParentMenu = ({ link, setActiveMenu }) => {
  const handleReturn = ({ currentTarget }) => {
    const { menuReturnId } = currentTarget.dataset
    const activeMenu = document.getElementById('containerMobileMenu').dataset
    setActiveMenu({
      active: parseInt(menuReturnId),
      prev: parseInt(activeMenu.prevMenu),
    })
  }

  return (
    <>
      <div
        className={`${styles.listItemHeader} js-parent-menu`}
        data-parent-id={link.id}
      >
        <MaxWidth>
          <ul className={styles.list}>
            <li
              data-menu-return-id={link.parentID}
              className={`${styles.listItem}`}
              onClick={handleReturn}
            >
              <Button
                extraClass={styles.btnLink}
                appearance="link"
                buttonText={link.label}
              />
            </li>
          </ul>
        </MaxWidth>
      </div>

      {link.children?.length > 0 &&
        link.children.map((item, idx) => {
          if (item?.children?.length > 0) {
            return (
              <MountParentMenu
                key={idx}
                link={item}
                setActiveMenu={setActiveMenu}
              />
            )
          }
          return null
        })}
    </>
  )
}

const MobileHeader = ({
  campusUrl,
  pageLanguages,
  languageId,
  searchBox,
  mainMenuElements,
  buttonText,
  phoneNumber,
  handleEventClickItemMenu,
  handleEventPhoneDataLayer,
  templateType,
  button,
}) => {
  const menuContainer = useRef(null)

  const [openSearch, setOpenSearch] = useState(false)
  const menuOpen = useRef(null)
  const menuClose = useRef(null)

  const setActiveMenu = (newActiveMenu = { active: 0, prev: null }) => {
    if (menuContainer.current) {
      menuContainer.current.dataset.activeMenu = newActiveMenu.active
      menuContainer.current.dataset.prevMenu = newActiveMenu.pre

      menuContainer.current.dataset.theme =
        newActiveMenu.active === 0 ? 'blue' : 'light'

      const menuParentItems =
        menuContainer.current.getElementsByClassName('js-parent-menu')
      for (let item of menuParentItems) {
        if (item.dataset.parentId === `${newActiveMenu.active}`) {
          item.classList.add(styles.active)
        } else {
          item.classList.remove(styles.active)
        }
      }

      const menuItems = menuContainer.current.getElementsByClassName('js-menu')
      for (let item of menuItems) {
        if (item.dataset.menuId === `menu-${newActiveMenu.active}`) {
          item.classList.add(styles.active)
        } else {
          item.classList.remove(styles.active)
        }
      }
    }
  }

  const handleToggleMenu = () => {
    menuOpen.current.classList.toggle(styles.close)
    menuClose.current.classList.toggle(styles.close)
    menuContainer.current.classList.toggle(styles.active)
    setActiveMenu({ active: 0, prev: null })
  }

  return (
    <>
      <header className={styles.containerMobile} data-theme="blue">
        <MaxWidth
          extraClass={styles.headerContainer}
          display="flex"
          justifyContent="space-between"
        >
          <div className={styles.logo}>
            <Logo loading="eager" fetchPriority="high" />
          </div>

          <div className={styles.menu}>
            <span onClick={() => setOpenSearch(true)}>
              <MenuSearch width="22px" height="22px" />
            </span>

            <svg
              ref={menuOpen}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 32 32"
              width="22px"
              height="22px"
              onClick={handleToggleMenu}
            >
              <path
                d="M29 3.5a.5.5 0 0 0 0-1v1Zm-26-1a.5.5 0 0 0 0 1v-1Zm26 14a.5.5 0 0 0 0-1v1Zm-26-1a.5.5 0 0 0 0 1v-1Zm26 14a.5.5 0 0 0 0-1v1Zm-26-1a.5.5 0 0 0 0 1v-1Zm26-26H3v1h26v-1Zm0 13H3v1h26v-1Zm0 13H3v1h26v-1Z"
                stroke="#ffffff"
                strokeWidth="1.5px"
              />
            </svg>

            <svg
              ref={menuClose}
              className={styles.close}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 64 64"
              width="22px"
              height="22px"
              onClick={handleToggleMenu}
            >
              <g
                className="close_svg"
                stroke="#001C47"
                strokeWidth="3.5px"
                strokeMiterlimit={10}
                strokeLinecap="round"
              >
                <path d="M57 57 7 7M57 7 7 57" />
              </g>
              <use href="close_svg" />
            </svg>
          </div>

          {searchBox === 'yes' && (
            <SearchBox
              id="search-box-id"
              open={openSearch}
              setOpen={setOpenSearch}
              templateType={templateType}
              button={button}
            />
          )}
        </MaxWidth>
        <div
          id="containerMobileMenu"
          ref={menuContainer}
          className={`${styles.containerMenus}`}
          data-theme="blue"
          data-active-menu="0"
          data-prev-menu="0"
        >
          {mainMenuElements.length > 0 &&
            mainMenuElements.map((link) => {
              if (link?.children?.length > 0) {
                return (
                  <MountParentMenu
                    key={link.id}
                    link={link}
                    setActiveMenu={setActiveMenu}
                  />
                )
              }
              return null
            })}
          <MaxWidth>
            <MountMenu
              menuId={0}
              menu={mainMenuElements}
              setActiveMenu={setActiveMenu}
              campusUrl={campusUrl}
              pageLanguages={pageLanguages}
              languageId={languageId}
              handleEventClickItemMenu={handleEventClickItemMenu}
            />
          </MaxWidth>
        </div>
      </header>
      <div>
        <StickyBottom
          buttonText={buttonText}
          number={phoneNumber}
          handleEventPhoneDataLayer={handleEventPhoneDataLayer}
        />
      </div>
    </>
  )
}

export default MobileHeader
