import { MaxWidth } from '@components/index'
import { SearchBox } from '@modules/index'
import { lazy, useState } from 'react'
import * as styles from './superheader.module.scss'
import { useI18n } from '@griddo/core'

const LangAndAccess = lazy(() => import('./LangAndAccess'))
const MenuSearch = lazy(() => import('./Search'))

import PhoneWhiteSvg from '@images/phone-white.svg'

const SuperHeader = ({
  visible,
  navigationLanguages,
  campusUrl,
  phoneNumber,
  searchBox,
  handleEventPhoneDataLayer,
  pageLanguages,
  languageId,
  button,
}) => {
  const { getTranslation } = useI18n()
  const [openSearch, setOpenSearch] = useState(false)

  return (
    <>
      <header
        className={`${styles.container} `}
        data-theme="blue"
        style={{
          '--z-index-super-header': visible ? '201' : '199',
        }}
      >
        <MaxWidth display="flex" justifyContent="flex-end">
          {phoneNumber?.length > 9 && (
            <div
              className={styles.iconPhone}
              onClick={handleEventPhoneDataLayer}
            >
              <PhoneWhiteSvg />
              <a href={`tel:${phoneNumber}`}>{phoneNumber}</a>
            </div>
          )}

          {searchBox === 'yes' && (
            <>
              <div
                className={styles.search}
                onClick={() => setOpenSearch(true)}
              >
                <MenuSearch />
                <span>{getTranslation('btnSearch')}</span>
              </div>
            </>
          )}

          <LangAndAccess
            visible={visible}
            navigationLanguages={navigationLanguages}
            campusUrl={campusUrl}
            pageLanguages={pageLanguages}
            languageId={languageId}
          />
        </MaxWidth>
      </header>
      {searchBox === 'yes' && (
        <SearchBox
          id="search-box-id"
          open={openSearch}
          setOpen={setOpenSearch}
          button={button}
        />
      )}
    </>
  )
}

export default SuperHeader
