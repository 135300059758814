import { Button, ImageTrans, MaxWidth } from '@components/index'
import SuperHeader from '@modules/Header/components/SuperHeader'
import { lazy, useEffect, useRef, useState } from 'react'
import * as styles from '@modules/Header/headers/desktop.module.scss'

const Logo = lazy(() => import('@modules/Header/components/Logo'))

import { useScrollPosition } from '@hooks/useScrollPosition'

import MainNavigationLargeIcon from '../../../../static/images_NO_VALE/svg/lines/MainNavigation/MainNavigationLarge.svg'

const MountDesktopLink = ({ link, handleEventClickItemMenu }) => {
  const isGroup = link?.children.length > 0 && 'isGroup'
  const typeLink = isGroup ? styles.isGroup : styles.isLink

  return (
    <li
      data-is-group={isGroup}
      data-level={`${link.level}`}
      data-link={link.id}
      className={`${styles.listItem} ${typeLink}`}
    >
      <Button
        extraClass={styles.btnLink}
        appearance="link"
        buttonText={link.label}
        href={link.url?.href}
        linkToURL={link.url?.linkToURL}
        newTab={link.url?.newTab}
        noFollow={link.url?.noFollow}
        onClick={handleEventClickItemMenu}
        state={{
          type: 'menu-header',
        }}
      />
      {link.children?.length > 0 && (
        <MountDesktopSubmenu
          menuId={link.id}
          level={link.level}
          items={link.children}
          image={link.image}
          handleEventClickItemMenu={handleEventClickItemMenu}
        />
      )}
    </li>
  )
}

export function MountDesktopSubmenu({
  items,
  level = 0,
  image,
  handleEventClickItemMenu,
}) {
  return level > 1 ? (
    <ul data-level={`menu-${level + 1}`} className={styles.list}>
      {items.length > 0 &&
        items.map((link, idx) => (
          <MountDesktopLink
            key={idx}
            link={link}
            menuType="desktop"
            handleEventClickItemMenu={handleEventClickItemMenu}
          />
        ))}
    </ul>
  ) : (
    <div data-level={`menu-${level}`} className={styles.submenu}>
      <MaxWidth
        extraClass={styles.childMenu}
        display="flex"
        justifyContent="space-between"
      >
        <div className={styles.menuVertical}>
          <ul data-level={`menu-${level + 1}`} className={styles.list}>
            {items.length > 0 &&
              items.map((link, idx) => (
                <MountDesktopLink key={idx} link={link} />
              ))}
          </ul>
        </div>
        <div className={styles.lineLarge}>
          <MainNavigationLargeIcon />
        </div>
        {image?.url && (
          <div className={styles.image}>
            <ImageTrans
              ratio={550 / 280}
              image={image}
              alt={image?.alt}
              responsive={[
                { breakpoint: null, width: '550px', height: '280px' },
              ]}
            />
          </div>
        )}
      </MaxWidth>
    </div>
  )
}

const DesktopHeader = ({
  mainMenuElements,
  buttonText,
  handleEventClickItemMenu,
  phoneNumber,
  navigationLanguages,
  campusUrl,
  searchBox,
  handleEventPhoneDataLayer,
  pageLanguages,
  languageId,
  button,
}) => {
  const wrapperRef = useRef(null)
  const [scrollPosition] = useScrollPosition(wrapperRef)
  const [visible, setVisible] = useState(true)

  useEffect(() => {
    setVisible(scrollPosition <= 15)
  }, [scrollPosition])

  const scrolling = visible ? '' : styles.top

  return (
    <>
      <SuperHeader
        visible={visible}
        navigationLanguages={navigationLanguages}
        campusUrl={campusUrl}
        phoneNumber={phoneNumber}
        searchBox={searchBox}
        handleEventPhoneDataLayer={handleEventPhoneDataLayer}
        pageLanguages={pageLanguages}
        languageId={languageId}
        button={button}
      />
      <header
        ref={wrapperRef}
        className={`${styles.containerDesktop} ${scrolling} `}
        data-theme="blue"
      >
        <MaxWidth
          extraClass={styles.headerContainer}
          display="flex"
          justifyContent="space-between"
        >
          <div className={styles.logo}>
            <Logo loading="eager" fetchPriority="high" />
          </div>

          <div className={styles.menu}>
            <div className={styles.menuHorizontal}>
              <ul data-level={'menu-0'} className={styles.list}>
                {mainMenuElements.length > 0 &&
                  mainMenuElements.map((link, idx) => (
                    <MountDesktopLink
                      handleEventClickItemMenu={handleEventClickItemMenu}
                      key={idx}
                      link={link}
                    />
                  ))}
              </ul>
            </div>
            {buttonText && (
              <Button
                extraClass={styles.menuCallToAction}
                buttonText={buttonText || 'SOLICITA INFORMACIÓN'}
                appearance="primary01"
                isLink="modal"
                modalContent={{ component: 'MoreInfoForm' }}
              />
            )}
          </div>
        </MaxWidth>
      </header>
    </>
  )
}

export default DesktopHeader
